<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0">
                {{ $t("message.new_m", {m: $t("message.outgoing_cost"),}) }}
            </p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">  
                      <el-form-item
                      prop="cost_type_id"
                      :label="$t('message.costType')"
                      >
                        <select-cost-type
                            :size="'mini'"
                            :placeholder="$t('message.costType')"
                            :id="form.cost_type_id"
                            v-model="form.cost_type_id"
                            @getDefaultCost="getDefaultCost" 
                            >
                        </select-cost-type>
                      </el-form-item>   
                    </el-col>

                    <el-col :span="12">
                      <el-form-item
                      prop="filial_id"
                      :label="$t('message.filial')"
                      >  
                        <select-filial
                            :size="'mini'"
                            :show_all="0"
                            :placeholder="columns.filial_id.title"
                            v-model="form.filial_id"
                        >
                        </select-filial>
                      </el-form-item>   
                    </el-col>

                    <el-col :span="12">  
                      <el-form-item
                      prop="payment_type_id"
                      :label="$t('message.paymentType')"
                      >
                        <select-payment-type
                            :size="'mini'"
                            :placeholder="columns.payment_type_id.title"
                            :id="form.payment_type_id"
                            v-model="form.payment_type_id"
                            >
                        </select-payment-type>
                      </el-form-item>   
                    </el-col>

                    <el-col :span="12">  
                      <el-form-item
                      prop="date"
                      :label="$t('message.date')"
                      >
                        <el-date-picker
                          class="w-100"
                          size="medium"
                          v-model="form.date"
                          type="datetime"
                          :placeholder="$t('message.date')"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm"
                        ></el-date-picker>
                      </el-form-item>   
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item
                      prop="bank_account_id"
                      :label="$t('message.bankAccount')"
                      >
                          <select-bank-account
                              :size="'mini'"
                              :placeholder="columns.bank_account_id.title"
                              :id="form.bank_account_id"
                              v-model="form.bank_account_id"
                              @getAccount="getAccount" 
                              >
                          </select-bank-account>
                      </el-form-item>   
                    </el-col>
                    <el-col :span="4">
                      <el-form-item
                        prop="money_amount"
                        :label="$t('message.money_amount')"
                        >
                          <crm-money-input
                            v-model="form.money_amount"
                            :old="form.money_amount"
                            :size="'medium'"
                          >
                          </crm-money-input>
                      </el-form-item>  
                    </el-col>
                    
                    <el-col :span="4">
                      <el-form-item
                      :label="$t('message.currency')"
                      >
                         {{ bankAccount.currency ?  bankAccount.currency.code : ''}}
                      </el-form-item>   
                    </el-col>
                    <el-col :span="4" v-if="bankAccount.currency">
                      <el-form-item
                      prop="currency_rate"
                      :label="$t('message.rate')"
                      >
                        <el-input
                          :disabled="bankAccount.currency ? (bankAccount.currency.active) : false"
                          type="number"
                          size="mini"
                          :placeholder="$t('message.rate')"
                          v-model="form.currency_rate">
                        </el-input>
                      </el-form-item>   
                    </el-col>
                    <el-col :span="4" v-if="bankAccount.currency">
                      <el-form-item
                      :label="$t('message.money_amount')"
                      >
                          <span>{{ form.currency_rate * form.money_amount  | formatMoney(2) }}</span>
                      </el-form-item>   
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="comment"
                      :label="$t('message.comment')"
                      >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2 }"
                            :placeholder="$t('message.comment')"
                            v-model="form.comment">
                          </el-input>
                      </el-form-item>   
                    </el-col>
                  </el-row>

                    <!-- end-col -->
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectPaymentType from "@/components/inventory/select-paymentType";
import selectBankAccount from "@/components/inventory/select-bank-account";
import selectCostType from "@/components/inventory/select-cost-type";
import selectFilial from "@/components/inventory/select-filial";

export default {
    mixins: [form, drawer],
    props: ['unsubmittable'],

    components: {
        selectPaymentType,
        selectBankAccount,
        selectCostType,
        selectFilial
    },
    data() {
        return {
            bankAccount: {},
            defaultCost: {},
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "costTransactions/rules",
            model: "costTransactions/model",
            columns: "costTransactions/columns",
        }),
    },
    watch: {
      bankAccount: {
          handler: function(newVal, oldVal) {
              if(this.bankAccount.currency){
                this.form.currency_rate = this.bankAccount.currency.rate;
                this.setDefaultMoney();
              }
          },
          deep: true,
          immediate: true
      },
      defaultCost: {
          handler: function(newVal, oldVal) {
            if(this.defaultCost.currency){
                this.setDefaultMoney();
            }
          },
          deep: true,
          immediate: true
      },
    },
    methods: {
        ...mapActions({
            save: "costTransactions/store",
            empty: "costTransactions/empty",
        }),
        afterOpen(){
          this.$set(this.form, 'date', this.formatDate());
        },
        checkSubmit(close){
          if(this.unsubmittable){
            this.$emit('newCost', this.form);
            if (close) this.close();
            this.empty()
          }else{
              this.loadingButton = true;
              this.save(this.form)
                .then((res) => {
                  this.loadingButton = false;
                  if(!this.model.filial_id || this.model.filial_id != this.form.filial_id){
                    this.parent().updateFilterOptions();
                  }
                  this.$alert(res);
                  this.parent().listChanged();
                  if (close) this.close();
                })
                .catch((err) => {
                  this.loadingButton = false;
                  this.$alert(err);
                });
          }
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
              if (valid) {
                this.checkSubmit(close);
              }
            });
        },
        setDefaultMoney(){
            if(this.defaultCost.currency && this.bankAccount.currency && (this.defaultCost.currency.id === this.bankAccount.currency.id)){
                this.form.money_amount = this.defaultCost.money_amount;
            }else{
                if(this.defaultCost.money_amount && this.defaultCost.currency && this.defaultCost.currency.rate){
                  let money = parseFloat(this.defaultCost.money_amount*this.defaultCost.currency.rate);
                  if(this.form.currency_rate > 0){
                      this.form.money_amount = _.round(money/this.form.currency_rate, 2);
                  }else{
                      this.form.money_amount = money;
                  }
                }
            }
        },
        getAccount(account){
            this.bankAccount = account;
        },
        getDefaultCost(cost){
            this.defaultCost = cost;
        }
    },
};
</script>
