<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.costType')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(costType,index) in costTypes" :key="'costTypes-' + index" :label="costType.name" :value="costType.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
            if(this.id && this.costTypes.length > 0 && this.costTypes.some(acc => acc.id === this.id)){
              let cost = this.costTypes.find(acc => acc.id === this.id);
              this.$emit('getDefaultCost', cost);
            }
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.costTypes && this.costTypes.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        costTypes: 'costTypes/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'costTypes/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
  